//src/components/TwitterEmbed.js

import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const TwitterEmbed = ({ tweetId }) => {
  return (
    <div>
      <TwitterTweetEmbed tweetId={tweetId} />
    </div>
  );
};

export default TwitterEmbed;
