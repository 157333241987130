/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// gatsby-browser.js 또는 해당 설정이 적용되어야 하는 파일
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import TwitterEmbed from './src/components/TwitterEmbed'; // 사용할 컴포넌트 경로

// 사용할 컴포넌트 목록
const components = {
  TwitterEmbed // MDX 파일 내에서 직접 사용할 수 있도록 컴포넌트 이름으로 매핑
};

export const wrapRootElement = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>;
};
